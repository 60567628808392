import React, { Component } from 'react';
import { Grid, Image, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './../styles/Home.css';
import './../styles/About.css';
import './../styles/animate.css';
import ControlledCarousel from './Carousel';
import Testimonial from './Testimonial';
// import {ClientSlider} from './clientSlider';



export default class Home extends Component {
  render() {
    return (
      <div>
        <ControlledCarousel />
        <div className="industryExp">
          <Grid>
            <p className="animated swing slower headlines"><strong>INDUSTRY EXPERTISE</strong></p>
            <hr className="rowColors" />
           <Col xs={12} sm={4} className="workImages chooseUs borderSpace">
            <h3 class="animated pulse slower delay-2s textCenter pcolor">Web Development</h3>
            <p></p>
          </Col>


          <Col xs={12} sm={3} className="workImages chooseUs borderSpace">
            <h3 class="animated pulse slower delay-2s textCenter">IT Staffing Solutions</h3>
            <p></p>
          </Col>

          <Col xs={12} sm={4} className="workImages chooseUs borderSpace">
            <h3 class="animated pulse slower delay-2s textCenter">Data Processing</h3>
            <p></p>
          </Col>
          </Grid>
        </div>

        <div className="spaceGap" />
        <Grid>
          <p className="animated bounce slower delay-1s headlines"><strong>ABOUT US</strong></p>
          <hr className="rowColor" />
          <Col xs={12} sm={8}>
            <Image src="assets/slider/cards.jpg" className="about-profile-pic workImages aboutImage" rounded />
            <p className="textDesc"><strong class="animated flash slower">Placjo Technologies Private Limites </strong> is Web Development &amp; Talent consulting firm specializing in Web Developement, Mobile Developement , Data Processing  &amp; Talent consulting solutions for companies across the india. </p>
            <p className="textDesc"> Placjo Technologies offers a complete range of web-based application services including strategic consulting, prototype development, site architecture planning, creative graphic design and photo imagery, eCommerce solutions, content management systems, content migration services, lead generation tools, advanced programming capabilities, multimedia production, SEO and site globalization services.</p>
            <p className="textDesc">Placjo Technologies  is a complete web and IT services provider with its operations in India. </p>
            <p className="textDesc">Our Primary Activity is permanent staff recruitment and temporary staffing for either single, large  volume or ongoing staffing requirement. We recruit at all the employment levels from junior to senior and across a broad range of employee categories.
            We Support organization in the Talent supply chain, offering them innovative, creative solutions and enabling them to have a comprehensive strategy for acquiring, managing and developing Talent.
            </p>
           
          </Col>
          <Col xs={12} sm={4} className="workImages chooseUs">
            <h3 class="animated pulse slower delay-2s textCenter">Why Choose US ?</h3>
            <hr className="rowColors" />
            <p className="pWhiite">Increase hiring speed</p>
            <p className="pWhiite">Improve quality of candidate</p>
            <p className="pWhiite">Reduce hiring, recruitment costs and agency fees</p>
            <p className="pWhiite">High level of ownership and partnership</p>
            <p className="pWhiite">A customized recruitment process</p>
            <p className="pWhiite">High level of Candidate Experience &amp; Engagement</p>
            <p></p>
          </Col>
        </Grid>


        <div className="spaceGap" />
        <div className="bgColor">
          <Grid className="paddingBottom">
            <div className="spaceGap" />
            <p class="animated shake slower delay-3s headlines"><strong> OUR SERVICES</strong> </p>
            <hr className="rowColor" />
            <br />
            <div class="row">
 
            <Col xs={12} sm={4} className="spaceTop borderStyle">
                <div className="textCenter"><i class="fa fa-opera faService"></i></div>
                <h2 className="serviceTitle">Web Development</h2>
                <p className="textDesc">We Provide full service for Website designing and website hosting solution that handles every aspect of your website while providing ongoing website updating solutions so your website stays protected and updated. We Develop website for business or any purpose</p>
              </Col>
            
              <Col xs={12} sm={4} className="spaceTop borderStyle">
                <div className="textCenter"><i class="fa  fa-headphones faService"></i></div>
                <h2 className="serviceTitle">Data Processing</h2>
                <p class="textDesc">We provide outsourcing Data Entry, Background Check, Chat and Email Support services like technical help, verification and others to our outsourcing company in India can help your organization by providing timely resolutions to all customer queries</p>
              </Col>
             
              <Col xs={12} sm={4} className="spaceTop borderStyle">
                <div className="textCenter"><i class="fa fa-mobile faService"></i></div>
                <h2 className="serviceTitle">Mobile App Development</h2>
                <p class="textDesc">If you are looking to build your Mobility Product which is not only intuitive and amazing, but also Secured, robust, then you are with the right partner. We are just one click away to discuss your requirement to design your next generation IOS or Android App.  </p>
              </Col>

              <Col xs={12} sm={4} className="spaceTop borderStyle">
                <div className="textCenter"><i className="fa fa-bullhorn faService"></i></div>
                <h2 className="serviceTitle">Recruitment</h2>
                <p class="textDesc">Bridging requirements between the employer and the employee has been a challenge in the ever changing market trends. We at Placjo constantly strive in bringing the right opportunity to the ones seeking growth in diverse verticals setting the pace in par with the changing trends.</p>
              </Col>
              <Col xs={12} sm={4} className="spaceTop borderStyle">
                <div className="textCenter"><i class="fa fa-users faService"></i></div>
                <h2 className="serviceTitle">Staffing</h2>
                <p class="textDesc">We strongly believe in right staffing, giving an edge to the progress of the organisation and its functioning. Staffing in the IT sector is our key focus. We strongly believe in right staffing, giving an edge to the progress of the organisation and its functioning. Staffing in the IT sector is our key focus.</p>
              </Col>
              <Col xs={12} sm={4} className="spaceTop borderStyle">
                <div className="textCenter"><i class="fa fa-user-plus faService"></i></div>
                <h2 className="serviceTitle">Payroll</h2>
                <p class="textDesc">Team of experts and a system driven technology is in place here at Placjo which assures an error free payroll system for your organisation. Giving you more freedom to explore your expert domain while we handle the HR and Payroll services for you maintaining transparency and efficiency. </p>
              </Col>
            </div>
          </Grid>
        </div>



        {/* <div className="backImage">
          <Grid>
            <p class="animated tada slower delay-4s headlines"><strong>OUR RECRUITMENT PROCESS</strong></p>
            <hr className="rowColor" />
            <br />&nbsp;
              <Col xs={12} sm={2} className="boxStyle">
              <Image src="assets/services/car1.jpg" className="images workImages processImageHeight imageStyle" />
              <h4 className="textCenter space10">1. Job Analysis</h4>
              <p className="textDesc">We capture the job tasks and requirements in a job description as this will assist us in knowing the objectives of the search. Starting recruitment with accurate Job analysis and Job Description insures the recruitment starts off on a proper track of success.</p>
            </Col>
            <Col xs={12} sm={1} className="imgMarginTop">
              <div className="d-flex justify-content-end processDiv">
                <i class="fa fa-chevron-right processIcon"></i>
              </div>
            </Col>
            <Col xs={12} sm={2} className="boxStyle">
              <Image src="assets/services/car2.jpg" className="images workImages processImageHeight imageStyle" />
              <h4 className="textCenter space10">2. Sourcing</h4>
              <p className="textDesc">This process involves advertising which is a common part of the recruitment process, often encompassing multiple media, such as the news paper, internet, publications, Window advertisement, career centres and graduate recruitment programs at universities.
          </p>
            </Col>
            <Col xs={12} sm={1} className="imgMarginTop">
              <div className="d-flex justify-content-end processDiv">
                <i class="fa fa-chevron-right processIcon"></i>
              </div>
            </Col>
            <Col xs={12} sm={2} className="boxStyle">
              <Image src="assets/services/car4.jpg" className="images workImages processImageHeight imageStyle" />
              <h4 className="textCenter space10">3. Screening & Selection</h4>
              <p className="textDesc">Suitability of the candidate is normal assessed by evaluating their skills, knowledge and relevant experience. Qualifications are assessed using our most advanced recruitment system application. job application and one-one interviews.</p>
            </Col>
            <Col xs={12} sm={1} className="imgMarginTop">
              <div className="d-flex justify-content-end processDiv">
                <i class="fa fa-chevron-right processIcon"></i>
              </div>
            </Col>
            <Col xs={12} sm={2} className="boxStyle">
              <Image src="assets/services/car3.jpg" className="images workImages processImageHeight imageStyle" />
              <h4 className="textCenter space10">4. OnBorading</h4>
              <p className="textDesc">RecruiteJobs consultancy helps new employees become productive members of your organisation. We provide a planned introduction to new employees</p>
            </Col>
          </Grid>
        </div> */}

        <div className="spaceGap">
          <Grid>
          <p className="animated bounce slower delay-1s headlines"><strong>CERTIFICATE</strong></p>
          <hr className="rowColor" />
          <Col xs={12} sm={8} className="spaceBottom" smOffset={2}>
          <Image src="assets/services/placjo-award.jpg" className="textCenter" width='100%'/>
          </Col>
          </Grid>
        </div>

        <div className="spaceGap" />
        <div className="bgColor">
          <Grid>
            <div className="spaceGap" />
            <p class="animated wobble slower delay-5s headlines"> <strong>SECTORS WE'VE WORKED WITH</strong></p>
            <hr className="rowColor" />
            <br />
            <Row>
            <Col xs={12} sm={3} className="textCenter"><Image src="assets/services/telec.jpg" rounded className="imageWidth imageGrayscale workImages" /> <h3>IT and Non IT </h3></Col>
            <Col xs={12} sm={3} className="textCenter"><Image src="assets/services/embedded1.jpg" rounded  className="imageWidth imageGrayscale workImages" /> <h3>Embedded / Semiconductor</h3></Col>
            <Col xs={12} sm={3} className="textCenter"><Image src="assets/services/construction.jpg" rounded  className="imageWidth imageGrayscale workImages" /> <h3>Construction &amp; Engg</h3></Col>
            <Col xs={12} sm={3} className="textCenter"><Image src="assets/services/medical.jpg" rounded  className="imageWidth imageGrayscale workImages" /> <h3>Medical / Healthcare</h3></Col>
            </Row>
          </Grid>
          <Grid className="paddingBottom">
          <Row>
            <Col xs={12} sm={3} className="textCenter"><Image src="assets/services/bank.jpg" rounded  className="imageWidth imageGrayscale workImages" /> <h3>Banking &amp; Finance</h3></Col>
            <Col xs={12} sm={3} className="textCenter"><Image src="assets/services/education.jpg" rounded  className="imageWidth imageGrayscale workImages" /> <h3>Education</h3></Col>
            <Col xs={12} sm={3} className="textCenter"><Image src="assets/services/audience.jpg" rounded  className="imageWidth imageGrayscale workImages" /> <h3>Events and PR</h3></Col>
            <Col xs={12} sm={3} className="textCenter"><Image src="assets/services/store.jpg" rounded  className="imageWidth imageGrayscale workImages" /> <h3>Retail, Trade &amp; Manufacturing</h3></Col>
         </Row>
          </Grid>
        </div>

        <div className="spaceGap" />
        <Grid>
          <p class="animated heartBeat slower delay-5s headlines"> <strong>MANAGEMENT</strong> </p>
          <hr className="rowColor" />
          <br />
          <Row className="show-grid text-center">
            <Col xs={12} sm={4} className="person-wraper" smOffset={4} smHidden={4}>
              <Image src="assets/generalPics/sonika1.jpg" circle width='50%'/>
              <h4><strong>Sonika Chickmath</strong></h4>
              <strong>CEO &amp; Managing Director</strong>
            </Col>
          </Row>
      </Grid>



        {/* <div className="spaceGap" />
        <div className="bgColorTop">
          <Grid >
            <div className="spaceGap">
              <p class="animated bounceIn slower delay-5s headlines"> <strong></strong> </p>
              <hr className="rowColor" />
              <Col xs={12} sm={8} className="spaceBottom" smOffset={2}>
                {/* <Image src="assets/services/partner.png" className="textCenter" width='100%' />
                 <Image src="assets/services/partner2.png" className="textCenter" width='100%' /> */}
                	{/* <ClientSlider />	 */}
              {/* </Col>
            </div>
          </Grid>
        </div> */} 



        <div className="bgClients">
          <Grid className="spaceTop">
            <p class="animated bounceIn slower delay-5s headlines spaceTop"> <strong>CLIENT SPEAK 	&#33; </strong> </p>
            <hr className="rowColor" />
            <Testimonial />
            <div className="spaceTop" />
            <br />
            <br />
          </Grid>
        </div>





        <div className="footers">
          <Grid className="paddingBottom">
            <div className="spaceGap" />
            <Col xs={12} sm={3}>
              <h4 className="textCenter">ABOUT PLACJO </h4>
              <hr className="rowColors" />
              <br />
              <p className="footerLocation"><strong>Placjo Technologies Private Limited</strong> is a Software developement &amp; Talent consulting company that provides cutting edge engineering solutions.
                  </p>
                    <p></p>
                    <p className="footerLocation">GSTIN - 29AAKCP6192R1Z9</p> 
                    <p className="footerLocation">CIN - U74999KA2019PTC125416</p> 
            </Col>

            <Col xs={12} sm={2} smOffset={1}>
              <h4 className="textCenter">QUICK LINKS</h4>
              <hr className="rowColors" />
              <ul className="footer-links ">
                <li><Link to="/about">ABOUT US<i class="arrow right"></i></Link></li>
                {/* <li><Link to="/blog">BLOG</Link></li> */}
                <li><Link to="/recruitementProcess">RECRUITEMENT PROCESS</Link></li>
                <li><Link to="/interviewTips">INTERVIEW TIPS</Link></li>
                <li><Link to="/career">CAREER</Link></li>
                <li><Link to="/contact">CONTACT US</Link></li>
              </ul>
            </Col>

            <Col xs={12} sm={3} smOffset={1}>
              <h4 className="textCenter">SOCIAL LINKS</h4>
              <hr className="rowColors" />
              <div className="social-links textCenter" >
                <a href="https://in.linkedin.com/company/recruitejobs-solutions/" target="blank" class="linkedin"><i className="fa fa-linkedin socilIcons"></i></a>
                <a href="https://www.twitter.com/kallayya890/" target="blank" class="twitter"><i className="fa fa-twitter socilIcons"></i></a>
                <a href="https://www.facebook.com/PlacjoTechnologies/" target="blank"><i className="fa fa-facebook socilIcons"></i></a>
                <a href="https://www.instagram.com/placjo/" class="instagram" target="blank"><i className="fa fa-instagram socilIcons"></i></a>
              </div>
              <p>Social Links</p>
            </Col>
            <Col xs={12} sm={2} >
              <h4 className="textCenter">LOCATION</h4>
              <hr className="rowColor30" />
              <p className="footerLocations">
                301, 8th Cross Rd, Sanjay Nagar, Marathahalli, Bengaluru, 560037
              </p>
            </Col>
          </Grid>

        </div>


      </div>
    )
  }
}
