import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import './../styles/Home.css';
import './../styles/carolous.css'
import {firstBanner, secondBanner, thirdBanner, fourthBanner } from '../images/sliderImages';

export default class ControlledCarousel extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      index: 0,
      direction: null,
      fade: null,
      slide: null
    };
  }



  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
      slide: e.slide,
      fade: e.fade
    });
  }

  render() {
    const { index, direction } = this.state;

    return (
      <div>

        <Carousel>
  
          <Carousel.Item>
            <img
              className="img-fluid"
              alt=""
              src={firstBanner}
              fluid
              style={{ width: '100%', height: '100%' }}
            />
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="img-fluid"
              alt=""
              src={secondBanner}
              fluid
              style={{ width: '100%', height: '100%' }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="img-fluid"
              alt=""
              src={thirdBanner}
              fluid
              style={{ width: '100%', height: '100%' }}
            />
          </Carousel.Item>

          <Carousel.Item>
            <img 
              className="img-fluid"
              alt=""
              src={fourthBanner}
              fluid
              style={{ width: '100%', height: '100%'  }}
            />
          </Carousel.Item>
          
        </Carousel>

      </div>
    );
  }
}
