import React, { Component } from 'react';
import './App.css';
import Home from './containers/Home';
import About from './containers/About';
import Blog from './containers/Blog';
import Career from './containers/Career';
import RecruitementProcess from './containers/RecruitementProcess';
import InterviewTips from './containers/InterviewTips';
import Contact from './containers/Contact';
import { BrowserRouter as  Router, Route } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';


class App extends Component {
  render() {
    return (
            <Router>
               <div>
                 <Header />
                   <Route exact path="/" component={Home} />
                   <Route path="/about"  component={About} />
                   <Route path="/blog" component={Blog} />
                   <Route path="/career"  component={Career} />
                   <Route path="/recruitementProcess"  component={RecruitementProcess} />
                   <Route path="/interviewTips"  component={InterviewTips} />
                   <Route path="/contact"  component={Contact} />
                 <Footer />
              </div>
            </Router>
    );
  }
}

export default App;


