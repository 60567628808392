import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (

            <div style={mainDiv}>
                <footer style={footer}>
                    &copy; Copyright 2020 - Placjo Technologies Pvt Ltd  , All Rights Reserved.
             </footer>
            </div>
        );
    }
}

const footer = {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    backgroundColor: "#293a4a",
    color: "white",
    textAlign: "center",
    padding: "10px",
    borderTop: '1px solid white',
};

const mainDiv = {
    flexDirection: 'row',
    flex: 1,
    display: 'flex'
}


export default Footer;
