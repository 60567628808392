import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import './../styles/CustomerNavBar.css';
import Logo from './Logo'

class Header extends Component {
  render(){
    return(
      <Navbar default collapseOnSelect>
        <Navbar.Header >
         <Link to="/"> <Logo className="d-inline-block align-top"/></Link>
            <Navbar.Toggle />
        </Navbar.Header>

          <Navbar.Collapse>
             <Nav pullRight className="mr-auto">
               <NavItem  eventkey={1} componentClass={Link} href="/"  to="/">
               Home
               </NavItem>
               <NavItem eventkey={2} componentClass={Link} href="/about" to="/about">
               About us
               </NavItem>
              {/* <NavItem eventkey={3} componentClass={Link} href="/Services" to="/Services">
               Services
               </NavItem> */}
               {/* <NavItem eventkey={4} componentClass={Link} href="/blog" to="/blog">
               Placed Candidates
               </NavItem> */}
               <NavItem eventkey={5} componentClass={Link} href="/recruitementProcess" to="/recruitementProcess">
               Recruitement Process
               </NavItem>
               <NavItem eventkey={6} componentClass={Link} href="/interviewTips" to="/interviewTips">
               Interview Tips
               </NavItem>
               <NavItem eventkey={7} componentClass={Link} href="/career" to="/career">
               Career
               </NavItem>
               <NavItem eventkey={8} componentClass={Link} href="/contact" to="/contact">
               Contact us
               </NavItem>
             </Nav>
            </Navbar.Collapse>

         </Navbar>

    );
  }
}


/*const headers={

        position: "fixed",
        left: "0",
        bottom: "0",
        width: "100%",
        backgroundColor: "#004165",
        color: "white",
        textAlign: "center",
        padding : "10px"

}*/

export default Header;
