import React , { Component } from "react";

export default class Logo extends Component {
  render() {
      return (
        <img src ="assets/logofonts.png" style={styles} />
    );
  }
}

const styles = {
  width:'200px',
  height:'55px'
}