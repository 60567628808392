import React, { Component } from 'react';
import { Grid, Image, Row, Col, Button, Jumbotron } from 'react-bootstrap';
import { connect } from 'react-redux';
import { contactAction } from './../actions';
import './../styles/Contact.css';

 class Contact extends Component {
  handleSubmit = (e) => {
   e.preventDefault();
   const title = this.getTitle.value;
   const message =  this.getMessage.value;
   const data = {
     id: new Date(),
     title,
     message
   }
   this.props.aa(data);
   console.log(data);
 }
  render(){
    return(
    <div>
    <Grid>
    <h1 class="animated bounceIn slower">HOW TO REACH US ? </h1>
    <hr style={{border: '1px solid #293a4a', width:"10%", textAlign:'center'}}/>
    </Grid>
    <br /> &nbsp;
    <br /> &nbsp;

    <Grid>
    <Col xs={12} sm={4} style={center}>
    <i class="fa fa-map-marker" style={{color:'#ff6c2c',fontSize:50}}></i>
    <br />
    <h4>ADDRESS</h4>
    <strong class="animated fadeInDown slower delay-2s"> 301, 8th Cross Rd, Vishwapriya Layout, Sanjay Nagar, Marathahalli, Bengaluru, 560037</strong>
    </Col>

    <Col xs={12} sm={4} style={center}>
    <i class="fa fa-envelope-o" style={{color:'#ff6c2c',fontSize:50,paddingLeft:"20px"}}></i>
    <br />
    <h4 style={{paddingLeft:"5px"}}>EMAIL ADDRESS</h4>
    <strong style={{paddingLeft:"5px"}} class="animated fadeInDown slower delay-2s"> info@placjo.com</strong>
    </Col>


    <Col xs={12} sm={4} style={center}>
    <i class="fa fa-mobile" style={{color:'#ff6c2c',fontSize:50}}></i>
    <h4>PHONE NUMBER</h4>
    <strong class="animated fadeInDown slower delay-4s">Mobile : 7975268442 </strong>
    </Col>
    </Grid>
<br />
    <Grid>
      
       <Col xs={12} sm={8} style={{textAlign:'center'}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.216574645492!2d77.69530861482187!3d12.957988990864786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1303088d81c7%3A0xde3ddbefff12a007!2sPlacjo%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1582132931091!5m2!1sen!2sin"    width="90%" height="520" frameborder="0"  allowfullscreen></iframe>
       </Col>
     <Col xs={12} sm={4} style={{borderRadius:"5px",backgroundColor:'#f2f2f2', padding: '20px'}}>
     <h2 className="animated fadeInDown slower delay-5s textCenter">SEND US A MESSAGE</h2>
     <hr className="rowColor" />
       <form onSubmit={this.handleSubmit}>
        <input type="text" required className="textDesign" placeholder="Your Name" ref={(input)=>this.getTitle = input}  /><br /><br />
        <input type="email" required className="textDesign" placeholder="Your Email" ref={(input)=>this.getEmail = input}  /><br /><br />
        <input type="text" required className="textDesign" placeholder="Subject" ref={(input)=>this.getSubject = input}  /><br /><br />
        <textarea required className="textDesign" rows="5" cols="28" placeholder="Your Message" ref={(input)=>this.getMessage = input} /><br /><br />
        <Button type="Submit">
        Submit
       </Button>
       </form>
       </Col>
    </Grid>
    <Col xs={12} style={{paddingTop:50}}>
    </Col>
    </div>
    )
  }
}


const mapStateToProps=(state)=> {
  return {
     posts : state
}}
const mapDispatchToProps=(dispatch)=>({
    aa:(data)=>dispatch(contactAction(data))
})



export default connect(mapStateToProps,mapDispatchToProps)(Contact);

const center = {
  textAlign:'center'
}