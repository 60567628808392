import React, { Component } from 'react';
import { Grid, Row, Col, Button, Badge, Table, Collapse } from 'react-bootstrap';
import './../styles/InterviewTips.css';

export default class InterviewTips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          "id": 1, "titleName": "Post Your Cv to This Top Portals", "description": "Everyday Update Your CV  @ 9 am to below protals", "d1": "Naukri", "d2": "Shine", "d3": "Indeed", "d4": "Monstar",
        },
        {
          "id": 2, "titleName": "HR Interview Questions", "description": "Coming Soon.."
        },
        {
          "id": 3, "titleName": "How to get Refrences Jobs", "description": "Step 1 : Ask reference and  keep in touch your Seniors or Colleagues.", "d1": "Step 2 :The best way to do so is by talking to people. Find seniors from your college and the friends of your seniors working in the company where you plan to get a referral and ask your friends and seniors to approach them or if possible directly drop them a mail letting them know how and why you decided to contact them. Forward them your CV and hope for the best.Generally,  they will reply back if their company has suitable openings for you.", "d2": "Step 3 : Put up your profile on LinkedIn.", "d3": "Try socialising with people in the right direction, catch hold of the right set of people and share your profile across to them.",
        },
        {
          "id": 4, "titleName": "Upgrade Latest Skills", "description": "Artificial Intelligence (AI)", "d1": "Machine Learning", "d2": "Robotic Process Automation or RPA", "d3": "Blockchain", "d4": "Edge Computing", "d6": "Virtual Reality and Augmented Reality", "d7": "Cyber Security", "d8": "Internet of Things", "d9": "ReactJS", "d10": "AngularJs",
        },
        {
          "id": 5, "titleName": "Join To This Social Media's", "description": "LinkedIn", "d1": "Facebook", "d2": "Twitter", "d3": "Instagram", "d4": "Pinterest",
        },

      ],
      selectedTab: 1
    }
  }



  render() {
    return (
      <div className="topSpace spaceBottom">
        <Col xs={12} sm={4}>
          <details>
            <summary> Post Your Cv to This Top Portals</summary>
            &nbsp;
            <p>Everyday Update Your CV  @ 9 am to below portals.</p>
            <ul>
              <li><a href="https://my.naukri.com/account/register/basicdetails" target="_blank"  className="hyperLink">Naukri</a></li>
              <li><a href="https://www.shine.com/registration/" target="_blank"  className="hyperLink">Shine</a></li>
              <li><a href="https://www.monsterindia.com/seeker/registration" target="_blank"  className="hyperLink">Monstar</a></li>
              <li><a href="https://www.indeed.jobs/career/Login" target="_blank"  className="hyperLink">Indeed</a></li>
            </ul>
          </details>
          &nbsp;
            <details>
            <summary>HR Interview Questions for Fresher and Experience</summary>
            &nbsp;
            <details>
              <summary>Tell me about yourself</summary>
              &nbsp;
              <strong>Good Morning Sir/Madam,</strong>
              <p> First of all thanks for giving this opportunity to introduce myself in front of you,</p>
              <p>My name is Akhil as per as my education qualification is concerned , I have done BE with ISE stream from RV college bengaluru with agreegate of 80%,</p>
              <p>I am good in programing langauges Core Java, J2EE, Sql, Bootstrap etc..</p> &nbsp;
              <p><strong>My strength</strong> is self confidence , possitive attitude and hard working boy towards my career and my life,</p> &nbsp;
              <p><strong>My weakness</strong> I can easily believe everyone and i cant say no when some one ask for help me, </p> &nbsp;
             <p><strong>Family</strong> As per as my family concerended , I am belongs to a middle class family , My father is agriculture , My mother is home maker and my brother is worrking in xyz company etc..</p>
              <p><strong>Why did you leave your last job ?</strong> Sir its a career move , i have learn a lot from my last job,but now i am looking for new challenges to broken my horizons and to gain a new skill set,</p>
              <p><strong>What are your strengths ? </strong>My time management skills are excellent and i am organized, I am always understand the value and time ,</p>
              <p>My greatest strength is my ability to focus on my work</p> &nbsp;
              <p><strong>Why are yoy applying for this job? </strong> I have applied for this vacancy bcz its execeelent match for my skills and experience, This role is exact the sort of role i am currently targeting and t am confidenent i will be able to make a major contributions,</p> &nbsp;
              <p><strong>Confidence and Overconfidence diff ?</strong> Confidence : Is an internal believe that i am the right person for this job,</p> &nbsp;
              <p><strong>Overconfidence : </strong> I am only the right person for this job,</p> &nbsp;
            
              <p><strong>Smart work and Hard work diff ? </strong>Smart work and hard work are related to each other, without being hard worker we cant be a smart maker ,</p>
              <p>Smart work comes from hardwork</p> &nbsp;

              <p><strong>Achievement in life ? </strong> My greatest achievent so for in my college i conducted hardware workshop ,</p> &nbsp;
              <p><strong>Are you applying for other jobs ?</strong>As you know i would like to work as software developer beyond your company i have also applied to xyz company and IBM company, </p> &nbsp;
              <p><strong>Would you lie for the company ? </strong> Its depends on the situations , If my lie create a positive impact on the company and it was usful for many people , then i will lie,</p> &nbsp;
            
              <p><strong>Goal ? </strong> My goal is to become successful person and make my family proud of me ,</p> &nbsp;
              <p><strong>Salary Exceptations ?</strong>I am more interested in the role then the pay and I except to be paid approproate money for this role based on my experience, </p>
              <p>its you also know that hight cost of living here in bangaluru etc..</p> &nbsp;
              <p><strong>Why should we hire you ? </strong> I have a good experience in that particular field (Java) and i think i have an excel wiz now to work fast and efficient,</p> &nbsp;
            
              <p><strong>Money or Work  ? </strong> I would say work is more important , if we work and achive company goals then abviously money would follow, I believe work to be prior,</p> &nbsp;
              <p><strong>Do you have any Questions ?</strong>Thanks for giving this opportunity, </p>
              <p>After my overall performance till now if i got selected what i need to improve and if i am not selected how can i succeed further, can you give any advise sir ?..</p>
             
              <p><strong>Success mean to you ? </strong> If i put a smile on someone face and make him happpy it is success for me.</p> &nbsp;
            
          </details>
          </details>
          &nbsp;
              </Col>
        <Col xs={12} sm={4}>
          <details>
          &nbsp;
            <summary>How to get References Jobs</summary>
            <p>Step 1 : Ask reference and  keep in touch your Seniors or Colleagues,</p>
            <p>Step 2 :The best way to do so is by talking to people. Find seniors from your college and the friends of your seniors working in the company where you plan to get a referral and ask your friends and seniors to approach them or if possible directly drop them a mail letting them know how and why you decided to contact them. Forward them your CV and hope for the best.Generally,  they will reply back if their company has suitable openings for you..</p>
            <p>Put up your profile on LinkedIn.</p>
            <p>Try socialising with people in the right direction, catch hold of the right set of people and share your profile across to them</p>
          </details>
          &nbsp;
            <details>
            <summary>Upgrade Latest Skills</summary>
            &nbsp;
            <ul>
              <li>Artificial Intelligence (AI)</li>
              <li>Machine Learning</li>
              <li>Robotic Process Automation or RPA</li>
              <li>Blockchain</li>
              <li>Edge Computing</li>
              <li>Virtual Reality and Augmented Reality</li>
              <li>Cyber Security</li>
              <li>Internet of Things</li>
              <li>ReactJS</li>
              <li>AngularJs</li>
            </ul>
          </details>
          &nbsp;
              </Col>
        <Col xs={12} sm={4}>
          <details>
          &nbsp;
            <summary>Join To This Social Media's</summary>
            &nbsp;
            <p>Register and update your profile in below social media's</p>
            <ul>
              <li><a href="https://www.linkedin.com/m/login/" target="_blank"  className="hyperLink">LinkedIn</a></li>
              <li><a href="https://www.facebook.com/" target="_blank"  className="hyperLink">Facebook</a></li>
              <li><a href="https://twitter.com/i/flow/signup" target="_blank"  className="hyperLink">twitter</a></li>
              <li><a href="https://www.instagram.com/" target="_blank"  className="hyperLink">Instagram</a></li>
              <li><a href="https://www.pinterest.com/" target="_blank"  className="hyperLink">Pinterest</a></li>
              <li><a href="https://github.com/" target="_blank"  className="hyperLink">Github</a></li>
              <li><a href="https://stackoverflow.com/" target="_blank"  className="hyperLink">Stack Overflow</a></li>
              <li><a href="https://www.quora.com/" target="_blank"  className="hyperLink">Quora</a></li>
              <li>Join JOBS Whats App groups</li>
            </ul>
          </details>
          &nbsp;

            <details>
            <summary>How to Select a Resume | CV File Name</summary>
            &nbsp;
            <p>Don't name your resume resume.doc. </p>
            <h5>It should be in below format</h5>
            <h4>YourName_ProfileName_Expereince_Location</h4>
            <h5>Ex: Kavya_JavaDeveloper_1Yr_Bengaluru</h5>
          </details>

          &nbsp;

      <details>
            <summary>Email subject line for Resume while sharing CV</summary>
            &nbsp;
            <p>It should be in below format</p>
            <h5>YourName_ProfileName_Expereince_Location_contact No </h5>
            <h6>Ex: Akhil_ UI Architect_ 5+ years Exp _ Bengaluru _ 9932351**** : Resume Attached</h6>
          </details>
        </Col>

        





        <Grid>
          <Col className="bottomEmptySpace">
          </Col>
        </Grid>
      </div>
    );
  }
}


