import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import './../styles/Testimonial.css'

export default class Testimonial extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null,
      fade:true,
      slide:null
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
      slide:e.slide,
      fade:e.true
    });
  }

  render() {
    const { index, direction } = this.state;

    return (
<div>

  <Carousel className="carousels">
      <Carousel.Item>   
           <Carousel.Caption>  
            <h3  className="testimonialsText"> &nbsp;	&ldquo; What Placjo did was to establish a regular hiring process, support us with our applicant tracking system and improve the quality of our hires.	&rdquo;</h3>
            <p className="feedback"> &#8208; &#8208; &#8208; MAP Systems</p>
          </Carousel.Caption>
    </Carousel.Item>
 

  <Carousel.Item>   
           <Carousel.Caption>  
           <h3 className="testimonialsText"> &ldquo; I am proud to be associated with Placjo and it has always surprised us by exceeding our expectations.	&rdquo;</h3>
           <p className="feedback"> &#8208; &#8208; &#8208; Tertium Technology Pvt. Ltd.</p>
          </Carousel.Caption>
  </Carousel.Item>

   <Carousel.Item>   
           <Carousel.Caption>  
           <h3 className="testimonialsText">&ldquo; Placjo understands our requirements. They know the brand and know that we want.	&rdquo;</h3>
           <p className="feedback"> &#8208; &#8208; &#8208; ARC Document Solutions</p>
          </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>   
           <Carousel.Caption>  
           <h3 className="testimonialsText">&ldquo; Placjo fullfilled our all positions within our TAT. Thank you Placjo	&rdquo;</h3>
           <p className="feedback"> &#8208; &#8208; &#8208; 4ngle Software Solutions Pvt Ltd</p>
          </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>   
           <Carousel.Caption>  
           <h3 className="testimonialsText">&ldquo; Placjo within a month closed our all IT &amp; Non IT requirements. Thank you Placjo	&rdquo;</h3>
           <p className="feedback"> &#8208; &#8208; &#8208; Raclac</p>
          </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>   
           <Carousel.Caption>  
           <h3 className="testimonialsText">&ldquo; Placjo understands our requirements. They know the brand and know that we want. Thank you Placjo	&rdquo;</h3>
           <p className="feedback"> &#8208; &#8208; &#8208; Elips Systems </p>
          </Carousel.Caption>
  </Carousel.Item>


</Carousel>
</div>
    );
  }
}
