import React, { Component } from 'react';
import { Image, Jumbotron, Grid, Col } from 'react-bootstrap';
import './../styles/Home.css';

export default class RecruitementProcess extends Component {
  render() {
    return (
      <div className="topSpace">
        <Grid>
          <Col xs={12} sm={8}>
            <Jumbotron>
              <h3 class="animated flip slower" style={{ textAlign: 'center' }}>Placjo</h3>
              <hr style={{ border: '1px solid #ff6c2c', width: "10%", textAlign: 'center' }} />
              <h4 style={{ textAlign: 'center' }}>Maintaining the Recruitment process</h4>
              <p>We understand that finding the best candidate is your top priority. </p>
            </Jumbotron>
            <p className="textDesc">Our team would identify the right blend of onsite, offsite and offshore resources to find quickly the Talent you need – a priority during the entire hiring and recruiting process. Our offering is designed to support your hiring needs, and can be customized for volume hiring and niche hiring.</p>
          </Col>
          <Col xs={12} sm={4}>
            <Image src="assets/slider/1.jpg" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }} width='100%' />
          </Col>
        </Grid>


        <Grid>
          <Col xs={12} sm={8}>
            <h4>Recruitment Process</h4>
            <p className="textDesc">Placjo  consultancy has recruiters who are specialised in resourcing executive personnel for companies in various industries.</p>
            <br />

            <h3 style={{ textAlign: 'center' }}>Our Process</h3>
            <hr style={{ border: '1px solid #ff6c2c', width: "10%", textAlign: 'center' }} />
            <h4>Job Analysis</h4>
            <p className="textDesc">We capture the job tasks and requirements in a job description as this will assist us in knowing the objectives of the search. Starting recruitment with accurate Job analysis and Job Description insures the recruitment starts off on a proper track of success</p>
            <br />

            <h4>Sourcing</h4>
            <p className="textDesc">This process involves advertising which is a common part of the recruitment process, often encompassing multiple media, such as the news paper, internet, publications, Window advertisement, career centres and graduate recruitment programs at universities.</p>
            <br />

            <h4>Screening  and Selection</h4>
            <p className="textDesc">Suitability of the candidate is normal assessed by evaluating their skills, knowledge and relevant experience. Qualifications are assessed using our most advanced recruitment system application. job application and one-one interviews.</p>
            <p className="textDesc">Our candidate screening methodology is based on matching client`s briefing, Job description and other details, such as length of service, job titles and period of time spent in the same job </p>
            <br />

            <h4>On boarding</h4>
            <p className="textDesc">Placjo  consultancy helps new employees become productive members of your organisation. We provide a planned introduction to new employees </p>
            <br />
          </Col>
          <Col xs={12} sm={4} style={{ border: '1px solid #293a4a', backgroundColor: '#293a4a' }}>
            <h3 class="animated lightSpeedIn slower delay-3s" style={{ textAlign: 'center' }}>Why Choose US ?</h3>
            <hr style={{ border: '1px solid #ff6c2c', width: "30%", textAlign: 'center' }} />
            <p style={{ color: 'white', fontSize: 15, fontWeight: '500', fontFamily: 'sans-serif' }}>Increase hiring speed</p>
            <p style={{ color: 'white', fontSize: 15, fontWeight: '500', fontFamily: 'sans-serif' }}>Improve quality of candidate</p>
            <p style={{ color: 'white', fontSize: 15, fontWeight: '500', fontFamily: 'sans-serif' }}>Reduce hiring, recruitment costs and agency fees</p>
            <p style={{ color: 'white', fontSize: 15, fontWeight: '500', fontFamily: 'sans-serif' }}>High level of ownership and partnership</p>
            <p style={{ color: 'white', fontSize: 15, fontWeight: '500', fontFamily: 'sans-serif' }}>A customized recruitment process</p>
            <p style={{ color: 'white', fontSize: 15, fontWeight: '500', fontFamily: 'sans-serif' }}>High level of Candidate Experience & Engagement</p>
            <p></p>
          </Col>
        </Grid>
        <Grid>
          <Col className="bottomEmptySpace">
          </Col>
        </Grid>
      </div>
    );
  }
}
