import React, { Component } from 'react';
import { Image, Grid, Col } from 'react-bootstrap';
import './../styles/About.css';
import './../styles/Home.css';

export default class About extends Component {
  render() {
    return (
      <div className="topSpace">
        <Grid>
          <Col xs={12} sm={8}>
            <Image src="assets/slider/p8.jpeg" className="about-profile-pic aboutImage" rounded />
            <h3>About<strong> Placjo  </strong></h3>
            <p className="textDesc">Established in 2019, Placjo Technologies is Web Development &amp; Talent consulting firm specializing in Web Developement, Mobile Developement , Data Processing  &amp; Talent consulting solutions for companies across the india.</p>
            <p className="textDesc"> Placjo Technologies offers a complete range of web-based application services including strategic consulting, prototype development, site architecture planning, creative graphic design and photo imagery, eCommerce solutions, content management systems, content migration services, lead generation tools, advanced programming capabilities, multimedia production, SEO and site globalization services.</p>
            <p className="textDesc">We Support organization in the Talent supply chain, offering them innovative, creative solutions and enabling them to have a comprehensive strategy for acquiring, managing and developing Talent</p>
           
           <p className="textDesc">At Placjo Technologies Pvt Ltd, we think outside the square and utilize creativity, innovation and skills to bring our clients a superior service every time. Every business is different and our clients come from diverse backgrounds so each project requires a new approach. We take pride in knowing our clients and their business, making it a priority part of our overall process.</p> 
           <p className="textDesc">Placjo Technologies Pvt Ltd is a complete web and IT services provider, with its operations in India. </p>
           
           <h3 className="textCenter">Placjo Services at a glance</h3>
            <hr className="rowColor" />
    <ul>
      <li className="textDesc">Web Developement </li>
      <li className="textDesc">Mobile Developement </li>
      <li className="textDesc">UX/UI Development</li>
      <li className="textDesc">Data Processing</li>
      <li className="textDesc">IT Staffing Solutions </li>
    </ul>


            <h3 className="textCenter">Our Commitments</h3>
            <hr className="rowColor" />
            <strong>We promise our Clients and Candidates that we are</strong>
            <p></p>
            <p className="textDesc">Personalised in our service</p>
            <p className="textDesc">Determined in our approach</p>
            <p className="textDesc">Responsive to your needs and outcome</p>
            <p className="textDesc">Resourceful in finding solutions</p>
            <p className="textDesc">Reliable in delivering the outcomes</p>

            <br />
            <h3 className="textCenter">Our Focus is to get the best results for our Clients</h3>
            <hr className="rowColor" />
            <p className="textDesc">Our growth is related to that of our clients</p>
            <p className="textDesc">Strong value system &amp; integrity reflects in all our transaction</p>
            <p className="textDesc">Deliveries are consistent with our commitments</p>
            <p className="textDesc">Resourceful in finding solutions</p>
            <p className="textDesc">With an eye for details in every aspect of our work</p>
          </Col>


          <Col xs={12} sm={4} className="workImages chooseUs">
            <h1 class="animated rollIn slow" ><i class="fa fa-eye aboutFa"></i>  &nbsp; Our Vission</h1>
            <hr className="rowColors" />
            <p className="pWhiite">To be a valued partner to our clients and an asset to our communities.</p>
            <p className="pWhiite">We will undertake and deliver any project, regardless of size, which we pursue.</p>
            <p className="pWhiite">To provide a differentiated offering resulting in value-for-value returns.</p>
            <p className="pWhiite">We will have the financial strength required to support our business objectives.</p>
          </Col>

          &nbsp;
            <Col xs={12} sm={4} className="workImages chooseUs ">
            <h1 class="animated fadeInDown slow"><i class="fa fa-rocket aboutFa"></i> &nbsp; Our Mision</h1>
            <hr className="rowColors" />
            <p className="pWhiite">Provide the highest quality service to our clients.</p>
            <p className="pWhiite">Consistently improve our deliverables to our clients and add value to your organization.</p>
            <p className="pWhiite">Support our employees in a way that fosters learning, growth and recognition for superior performance.</p>
            <p className="pWhiite">We develop long-term relationships with our customers and cooperation partners based on professionalism and loyalty.</p>
          </Col>
          &nbsp;
        </Grid>
        <Grid>
          <Col className="bottomEmptySpace">
          </Col>
        </Grid>

      </div>
    )
  }
}
