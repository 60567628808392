import React, { Component } from 'react';
import {Row, Col, Button, Badge, Table, Collapse, Jumbotron, Grid } from 'react-bootstrap';
import './../styles/Home.css';
import './../styles/Career.css';

export default class Career extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          "id": 2, "profilename": "HR Recruiters for International process(US)", "exp": "3 to 5 year", "ctc": " 4 - 7 LPA ", "location": "Bangalore RT Nagar", "keyskills": "Good Communication Required", "jd": "interviewing and assessing prospective applicants and matching them with vacancies at client companies, screening candidates and drawing up shortlists of candidates for clients to interview,organizing interviews and selection events,Sourcing from Job Portals,Responsible for handling IT requirements from US-based clients.Comfortable to work in night shift and must be able to commute by themselves."}, 
        {
          "id": 1, "profilename": "PHP Developer", "exp": "1 to 5 year", "ctc": " 4 - 8 LPA ", "location": "Bangalore  RT Nagar", "keyskills": "Should Worked On product base projects ", "jd": "• Must have working experience in Core PHP, MVC framework like (Code Igniter/ LA ravel / Cake PHP / Zend / Symfony , Brief knowledge on SMTP, and email server configuration, CPANEL.Hands-on Experience with Advance PHP in MVC structured coding.Understand Project requirement & develop applications accordingly with given design files.Expertise with MySQL and DBMS Concept ,Working knowledge of HTML5, CSS3, JavaScript, jQuery.Knowledge of CMS like WordPress ,Good know-how of mobile apps API will be considered - JSON, XML,REST"
        },
        {
          "id": 3, "profilename": "BDE experience in International Sales", "exp": "1 to 4 year", "ctc": " 3 - 6 LPA ", "location": "Bangalore RT Nagar", "keyskills": " 1 – 4 years’ experience into Inside Sales / Lead Generation.Experience in selling into International markets will be preferredGood phone and email communication skills.Self-Motivated, Quick Learner with interest in product sales.Strong customer service and teamwork skills.Must be willing to work UK / US timings", "jd": "Making cold calls to International as well as domestic clientsGenerate new leads and set appointments for the Business Development Managers,within defined territory, for product salesWorks closely with Business Development Managers to qualify prospects, coordinatemeetings, and drive new opportunities"
        },
        {
          "id": 4, "profilename": "Java Team Lead", "exp": "5 to 8", "ctc": " 6 - 8 LPA ", "location": "bangalore", "keyskills": "java", "jd": "Should know angularjs and Java , Spring and Hibernate"
        }, 
        {
          "id": 5, "profilename": "Lead Generation Executive", "exp": "0 to 1 year", "ctc": " 2 - 3 LPA ", "location": "bangalore", "keyskills": "Excellent listening, oral, and written communications ", "jd": " A Lead generation Executive is Responsible for generating leads both industry specific and product specific.  * Generate quality leads by Prospect Research,Industry Research.  Map the current prospect issues to our services/products to get more strategic clients.  Must have the ability to ask questions and listen"
        },
        {
          "id": 6, "profilename": "Front End Developer", "exp": "4 to 6 year", "ctc": " 8 - 15 LPA ", "location": "bangalore", "keyskills": "ReactJs, Redux, HTML,BOOTSTRAP, CSS ", "jd": "4+ years experience in frontend web application development.Strong knowledge and experience working with React, Redux, HTML5, CSS and Bootstrap.Proficient understanding of client-side scripting and JavaScript frameworks.Knowledge of CSS pre-processors such as LESS and SASS/ SCSS.Proficient understanding of cross-browser compatibility issues and ways to work around themKnowledge of CSS animations, Jest, Webpack, package manager (node/npm/yarn) and Babel are a plus.Ability to work and communicate clearly and efficiently with team members, with a strong English communication both written and spoken.Experience with enterprise-scale systems a major plus."
        },
        {
          "id": 7, "profilename": "Senior Front End Developer", "exp": "6 to 8 year", "ctc": " 10 - 19 LPA ", "location": "bangalore","keyskills": "ReactJs, Redux, HTML,BOOTSTRAP, CSS ",  "jd": "4+ years experience in frontend web application development.Strong knowledge and experience working with React, Redux, HTML5, CSS and Bootstrap.Proficient understanding of client-side scripting and JavaScript frameworks.Knowledge of CSS pre-processors such as LESS and SASS/ SCSS.Proficient understanding of cross-browser compatibility issues and ways to work around themKnowledge of CSS animations, Jest, Webpack, package manager (node/npm/yarn) and Babel are a plus.Ability to work and communicate clearly and efficiently with team members, with a strong English communication both written and spoken.Experience with enterprise-scale systems a major plus."
        },
        {
          "id": 8, "profilename": "Lead Front End Developer", "exp": "9+ year", "ctc": " 15 - 23 LPA ", "location": "bangalore", "keyskills": "ReactJs, Redux, HTML,BOOTSTRAP, CSS ",  "jd": "4+ years experience in frontend web application development.Strong knowledge and experience working with React, Redux, HTML5, CSS and Bootstrap.Proficient understanding of client-side scripting and JavaScript frameworks.Knowledge of CSS pre-processors such as LESS and SASS/ SCSS.Proficient understanding of cross-browser compatibility issues and ways to work around themKnowledge of CSS animations, Jest, Webpack, package manager (node/npm/yarn) and Babel are a plus.Ability to work and communicate clearly and efficiently with team members, with a strong English communication both written and spoken.Experience with enterprise-scale systems a major plus."
        },
        {
          "id": 9, "profilename": "Senior VB.net Engineer", "exp": "9+ year", "ctc": " 15 - 23 LPA ", "location": "bangalore", "keyskills": ".NET web framework",  "jd": "5+ years strong experience in .NET web frameworkProficient in VB.NET, with a good knowledge of their ecosystemsExperience building enterprise-grade web applications in an AGILE environment, using .NET FrameworkStrong understanding of object-oriented programming and familiarity with Microsoft SQL ServerStrong knowledge of design patterns and SOLID principlesProponent of continuous integration, unit testing, and automation."
        },
        {
          "id": 10, "profilename": "Senior Back End Developer", "exp": "9+ year", "ctc": " 15 - 23 LPA ", "location": "bangalore", "keyskills": ".net core , mongo db and Elasticsearch",  "jd": "5+ years experience in software development.Experience building enterprise-grade web application in a Agile environment, using C# / .NET Core 2.0Strong knowledge of design patterns and SOLID principles.Extensive experience building Web APIs.Experience working with SQL Server, MongoDB and ElasticSearch.Proponent of continuous integration, unit testing and automationGood to have: knowledge of Amazon Web Services (AWS), good understanding of Web Standards, EventStore, OWASP security principles and data encryption/security.Ability to work and communicate clearly and efficiently with team members, with a strong command of English both written and spoken."
        },
        {
          "id": 11, "profilename": "Lead Back End Developer", "exp": "9+ year", "ctc": " 15 - 23 LPA ", "location": "bangalore", "keyskills": ".net core , mongo db and Elasticsearch",  "jd": "7+ years experience in software development.Experience building enterprise-grade web application in a Agile environment, using C# / .NET Core 2.0Strong knowledge of design patterns and SOLID principles.Extensive experience building Web APIs.Experience working with SQL Server, MongoDB and ElasticSearch.Ability to produce high-quality technical design and technical specifications.Proponent of continuous integration, unit testing and automationGood to have: knowledge of Amazon Web Services (AWS), good understanding of Web Standards, EventStore, OWASP security principles and data encryption/security.Ability to work and communicate clearly and efficiently with team members, with a strong command of English both written and spoken."
        },
      ],
      selectedTab: 1 , open: false,
    }
  }


  renderList() {
    const { open } = this.state;
    return this.state.data.map((item,open) => {
      return (
       
          <Grid key={item.id} style={{marginBottom:'15px'}}>
           
              <Col xs={12} sm={12}>
                <Button variant="primary" size="lg" block
                  onClick={() => this.setState({ selectedTab: item.id, open: !open.id })}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                 {item.profilename}  <i class="fa fa-plus" style={{fontSize:"15px",color:'#ff6c2c'}}></i>
                </Button>
               
                <Collapse in={this.state.open === open.id || this.state.selectedTab === item.id } >
                  <div id="example-collapse-text" className="boxStyle">
                    <div style={{ border: '1px solid #222e3d', padding: '5px' }}>
                      <Col style={{flexDirection: "row", display: 'flex', justifyContent: "space-between",marginTop:'10px'}}>
                        <Col><strong>Exp : </strong>  {item.exp} </Col>
                        <Col><strong>CTC : </strong>  {item.ctc} </Col>
                        <Col><strong>Location : </strong>{item.location}</Col>
                      </Col>  
                      <hr></hr>
                      <Table>
                        <tr>
                          <th style={{width:"100px"}}>Keyskills &nbsp; :</th>
                          <td>{item.keyskills}</td>
                        </tr> &nbsp;
                        
                        <tr>
                          <th>Job Desc &nbsp;:</th>
                          <td>{item.jd}</td>
                        </tr>
                      </Table>
                    </div>
                    <div style={{ borderBottom: '1px solid black ', borderRight: '1px solid black ', borderLeft: '1px solid black ', flexDirection: "row", display: 'flex', justifyContent: "space-around", paddingTop: 10, backgroundColor: "#293a4a" ,color:"#ff6c2c" }}>
                      <p className="pWhiite">Posted Date : Few hours ago</p>
                      <p className="pWhiite">share cv to : <strong><a href="mailto:career@placjo.com">career@placjo.com</a></strong></p>
                    </div>
                  </div>
                </Collapse>
              </Col> 
              
              
          </Grid>
         

       

      );
    });
  }

  render() {
   
    return (
      <div className="bottomEmptySpace">
            <Jumbotron >
              <h2 class="animated flip slower" style={{ textAlign: 'center' }}>CAREER</h2>
              <hr style={{ border: '1px solid #222e3d', width: "10%", textAlign: 'center' }} />
             <h4 style={{ textAlign: 'center' }}>BE ONE OF US. <br></br><p></p>
              TURN YOUR PASSION INTO SOMETHING GREAT.</h4>
           </Jumbotron>
       
        <Col xs={12} sm={8} style={{}}><ul style={{position: "relative",paddingBottom:'100px'}}>{this.renderList()}</ul></Col>
          <p>&nbsp;</p>
      </div>
    );
  }
}


