import React, { Component } from 'react';
import {Grid, Image, Row, Col,Jumbotron} from 'react-bootstrap';
import './../styles/Home.css';
import './../styles/Blog.css';

export default class Blog extends Component {
  render(){
    return(
    <div className="backImages">
    <p class="animated slideInLeft slower headlines">Congratulations to Recent Placed Candidates.. </p>

    <Grid>
    <Jumbotron>
    <Row>
     <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/pavan.jpeg" circle className ="profile-pic"  width='100%' height={120}/><h4>Pavan</h4><h5 className="designation">Jr.DataBase Admin</h5></Col>
     <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/manoj.jpeg" circle className ="profile-pic" width='100%' height={120}/><h4>Manoj</h4><h5 className="designation">BDE</h5></Col>
     <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/harshita.jpeg" circle className ="profile-pic" width='100%' height={120}/><h4>Harshita</h4><h5 className="designation">TDL Developer</h5></Col>
     <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/kavitha.jpg" circle className ="profile-pic" width='100%' height={120}/><h4>Kavitha</h4><h5 className="designation">Hr & Receptionist</h5></Col> 
    </Row>

    <Row style={{paddingTop:'30px'}}>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/rekha.jpg" circle className ="profile-pic" width='100%' height={120}/><h4>Rekha</h4><h5 className="designation">BDE</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Lohith V Gowda</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Sankar</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Deepika</h4><h5 className="designation">Software Engineer</h5></Col>
    </Row>

    <Row style={{paddingTop:'30px'}}>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Puneeth</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Arun Kumar</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Neha verma</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Archana Nayak</h4><h5 className="designation">Software Engineer</h5></Col>
    </Row>

    <Row style={{paddingTop:'30px'}}>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Spoorthi</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Feroz</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Thiyagarajan</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Sushma</h4><h5 className="designation">Software Engineer</h5></Col>
    </Row>

    <Row style={{paddingTop:'30px'}}>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Arvind Kumar</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Madhura</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Ravi Kuran</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Abhishekh</h4><h5 className="designation">Software Engineer</h5></Col>
    </Row>

    <Row style={{paddingTop:'30px'}}>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Nandan</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Debajyoti</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Sambit</h4><h5 className="designation">Software Engineer</h5></Col>
    <Col xs={12} sm={3} className="textCenter"><Image src="assets/placed/emptyImage.png" circle className ="profile-pic" width='100%' height={120}/><h4>Sudharani</h4><h5 className="designation">Software Engineer</h5></Col>
    </Row>

   </Jumbotron>
   </Grid>

   <p class="animated slideInLeft slower headlines">Thank you.. </p>
   
    </div>
    )
  }
}
